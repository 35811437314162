<template>
  <v-app :style="appStyles">
    <ZnapNavigationDrawer
      v-if="showAppComponents && !isMobile"
      :miniVariant="getMiniVariant"
      :menus="menus"
      :selectedMenu="selectedMenu"
      @handleToggleMiniVariant="setMiniVariant(!getMiniVariant)"
      @handleNavigate="navigate($event)"
      @handleOpenInNewTab="openInNewTab($event)"
      @handleLogout="logout()"
      data-testid="menus-navigation"
    />

    <ZnapMobileNavigationDrawer
      v-if="showAppComponents && isMobile"
      :menus="menus"
      :selectedMenu="selectedMenu"
      @handleNavigate="navigate($event)"
      @handleLogout="logout()"
    />

    <v-main :style="mainStyles">
      <ZnapMainHeader
        v-if="showAppComponents"
        :selectedMenu="selectedMenu"
        @handleNavigateToHome="navigateToHome()"
      />

      <router-view />
    </v-main>

    <ZnapMainFooter v-if="!isMobile" />
  </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import theme from "@/plugins/theme";
import ZnapNavigationDrawer from "@/components/ZnapNavigationDrawer";
import ZnapMobileNavigationDrawer from "@/components/ZnapMobileNavigationDrawer";
import ZnapMainHeader from "@/components/ZnapMainHeader.vue";
import ZnapMainFooter from "@/components/ZnapMainFooter.vue";
import store from "@/store";

export default {
  name: "App",

  components: {
    ZnapNavigationDrawer,
    ZnapMobileNavigationDrawer,
    ZnapMainHeader,
    ZnapMainFooter,
  },

  data() {
    return {
      menus: this.getMenu()
    };
  },

  computed: {
    ...mapGetters("auth", ["isAuth"]),
    ...mapGetters("navigationdrawer", ["getMiniVariant"]),

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    appStyles() {
      return {
        background:
          "linear-gradient(180deg, rgba(141, 198, 63, 1) 0%, rgba(123, 161, 71, 1) 10%, rgb(0, 0, 0) 25%)",
      };
    },

    mainStyles() {
      if (!this.showAppComponents || this.isMobile) {
        return {
          padding: 0,
        };
      }

      return {
        padding: this.getMiniVariant ? "0 0 0 68px" : "0 0 0 356px",
      };
    },

    showAppComponents() {
      return this.isAuth && this.$route.name !== "LoginView";
    },

    selectedMenu() {
      const menu = this.menus.find((m) => this.$route.path.includes(m.route));
      return menu ? menu : {};
    },
  },

  created() {
    this.setTheme();
  },

  methods: {
    ...mapActions("auth", ["setLogout"]),
    ...mapMutations("navigationdrawer", ["setMiniVariant"]),

    setTheme() {
      this.$vuetify.theme.themes.light = theme;
    },

    navigate(menu = null) {
      if (!menu) {
        if (this.$route.path !== "/leads") {
          this.$router.push("/leads");
        }

        return;
      }

      if (this.selectedMenu && this.selectedMenu.id === menu.id) {
        if (this.$route.path === this.selectedMenu.route) {
          return;
        }
      }

      this.$router.push(menu.route);
    },

    navigateToHome() {
      if (this.$route.path !== "/leads") {
        this.$router.push("/leads");
      }
    },

    openInNewTab(menu) {
      let url = null;

      if (window.location.href.includes(":80")) {
        url = "http://localhost:8080";
      } else {
        url = "https://leads.audionovabrasil.com.br";
      }

      window.open(`${url}${menu.route}`, "_blank");
    },

    async logout() {
      const setLogoutRes = await this.setLogout(null);

      if (setLogoutRes.status === "success") {
        this.$router.push("/");
      }
    },

    getMenu() {
      const transactionsCode = store['getters']['auth/getPermissions'].filter( t => {
        return !!(t === 'READ_RESALE' || t === 'READ_CAMPAIGN' || t === 'READ_LEAD' || t === 'READ_LEAD_REPORT');
      })
      const menus = [
        {
          id: 1,
          text: "Página inicial",
          icon: "mdi-home-outline",
          route: "/home",
        },
        {
          id: 2,
          text: "Perfil do usuário",
          icon: "mdi-account-outline",
          route: "/user-profile",
          hide: true,
        },
        {
          id: 3,
          text: "Segmentações",
          icon: "mdi-bullhorn-outline",
          route: "/campaigns",
          transactionCode: 'READ_CAMPAIGN'
        },
        {
          id: 4,
          text: "Leads",
          icon: "mdi-bullseye",
          route: "/leads",
          transactionCode: 'READ_LEAD'
        },
        {
          id: 5,
          text: "Ponto de Atendimento",
          icon: "mdi-store-outline",
          route: "/resale",
          transactionCode: 'READ_RESALE'
        },
        {
          id: 6,
          text: "Configurações do sistema",
          icon: "mdi-cog-outline",
          route: "/system-configs",
        },
        {
          id: 7,
          text: "Alterar senha",
          icon: "mdi-lock",
          route: "/change-password",
          hide: true,
        },
        {
          id: 8,
          text: "Relatório de Leads",
          icon: "mdi-file-chart-outline",
          route: "/lead-report",
          transactionCode: 'READ_LEAD_REPORT'
        },
      ].map(m => {
        if(m.transactionCode) {
          if(!transactionsCode.includes(m.transactionCode)) {
            m.hide=true;
          }
        }
        return m;
      });
      return menus;
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --signal-color-violet: "#6F1E82";
}

body,
.v-application {
  font-family: "Inter", sans-serif !important;
}

.v-navigation-drawer--permanent {
  width: 356px;
  flex: 0 0 356px;
}

.cursor-pointer {
  cursor: pointer;
}

.Vue-Toastification__toast.Vue-Toastification__toast--success {
  background-color: #f78e1e !important;
}

.Vue-Toastification__toast.Vue-Toastification__toast--error {
  background-color: #e4003e !important;
}
</style>
